import React from 'react';
import Attendance from '../../assets/icons/attendance.svg';
import Calendar from '../../assets/icons/calendar.svg';
import Countdown from '../../assets/icons/countdown.svg';
import Gallery from '../../assets/icons/gallery.svg';
import GPS from '../../assets/icons/gps.svg';
import Music from '../../assets/icons/music.svg';
import Personalize from '../../assets/icons/personalize.svg';
import Present from '../../assets/icons/present.svg';
import Hashtag from '../../assets/icons/hashtag.svg';
import Tickets from '../../assets/icons/tickets.svg';
import Recommendations from '../../assets/icons/recommendations.svg';
import SaveTheDate from '../../assets/icons/save-the-date.svg';
import styles from './Options.module.scss';

const Options = () => (
  <section className={styles['options-wrapper']} data-aos="zoom-in">
    <div className="container">
      <h3>Contenido para tu Invitación</h3>
      <div className={styles['cards-container']}>
        <div className={styles['box']} data-aos="fade-up">
          <img src={Personalize} alt="Personalize" data-aos="flip-right" />
          <p className={styles.title}>La primera impresión jamás se olvida</p>
          <p className={styles.subtitle}>Personaliza tu invitación para facilitar la vida de tus invitados, permitiéndoles acceder a la información del evento desde cualquier lugar.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={Countdown} alt="Countdown" data-aos="flip-right" />
          <p className={styles.title}>Cuenta regresiva</p>
          <p className={styles.subtitle}>¿Contando los días para tu evento? Mantén a tus invitados emocionados con un contador que marca los días, horas, minutos y segundos hasta el gran día.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={Attendance} alt="Attendance" data-aos="flip-right" />
          <p className={styles.title}>Confirmación de asistencia</p>
          <p className={styles.subtitle}>Con esta opción, tendrás la certeza de quiénes asistirán a tu evento al permitir que tus invitados confirmen su asistencia fácilmente a través de WhatsApp con un mensaje personalizado.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={Calendar} alt="Calendar" data-aos="flip-right" />
          <p className={styles.title}>Nadie olvidará esa fecha tan especial</p>
          <p className={styles.subtitle}>Tus invitados podrán añadir el evento a sus calendarios para recibir recordatorios anticipados, asegurando así su presencia en tu ocasión especial.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={GPS} alt="GPS" data-aos="flip-right" />
          <p className={styles.title}>¡Tus invitados ya no se perderán!</p>
          <p className={styles.subtitle}>Muestra la ubicación exacta del lugar del evento y permite que el GPS guíe a tus invitados directamente.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={Gallery} alt="Gallery" data-aos="flip-right" />
          <p className={styles.title}>Galería</p>
          <p className={styles.subtitle}>Crea una galería multimedia con fotografías o videos.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={Present} alt="Present" data-aos="flip-right" />
          <p className={styles.title}>Mesa de Regalos</p>
          <p className={styles.subtitle}>Con un solo clic, tus invitados podrán seleccionar un obsequio de tu lista de regalos de manera sencilla y directa. Además, puedes incluir tus datos bancarios para aquellos que prefieran hacer contribuciones.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={Hashtag} alt="Hashtag" data-aos="flip-right" />
          <p className={styles.title}>Hashtag del Evento</p>
          <p className={styles.subtitle}>Utiliza un hashtag único en tus redes sociales para compartir contenido antes, durante y después del evento. Es una manera de invitar e involucrar a tus invitados para que sigan de cerca todos los detalles.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={Music} alt="Music" data-aos="flip-right" />
          <p className={styles.title}>Música</p>
          <p className={styles.subtitle}>Agrega un tema musical o graba un mensaje especial para tus invitados.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={Tickets} alt="Tickets" data-aos="flip-right" />
          <p className={styles.title}>Boletos personalizados</p>
          <p className={styles.subtitle}>Cada boleto incluye el nombre de la familia o invitado y un código QR personalizado que muestra los detalles del evento, el número de pases asignados y facilita el control de acceso.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={Recommendations} alt="Recommendations" data-aos="flip-right" />
          <p className={styles.title}>Recomendaciones</p>
          <p className={styles.subtitle}>Agrega un itinerario del evento, código de vestimenta, medidas de seguridad, detalles sobre invitados especiales, información sobre hospedaje y cualquier otro dato relevante que desees incluir.</p>
        </div>

        <div className={styles['box']} data-aos="fade-up">
          <img src={SaveTheDate} alt="SaveTheDate" data-aos="flip-right" />
          <p className={styles.title}>Save the Date</p>
          <p className={styles.subtitle}>Si aún no tienes todos los detalles de tu evento o será en una fecha popular o en un destino lejano, considera enviar una pre-invitación para que tus invitados puedan reservar la fecha con anticipación.</p>
        </div>
      </div>
    </div>
  </section>
);

export default Options;
