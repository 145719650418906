import React from 'react';
import { Link } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import Arrow from '../../assets/icons/arrow.svg';
import BabyIcon from '../../assets/icons/baby.svg';
import BirthdayIcon from '../../assets/icons/birthday.svg';
import GraduationIcon from '../../assets/icons/graduation.svg';
import ReligiousIcon from '../../assets/icons/religious.svg';
import WeddingIcon from '../../assets/icons/wedding.svg';
import XVIcon from '../../assets/icons/xv.svg';
import 'react-multi-carousel/lib/styles.css';

const CarouselCat = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="container">
      <Carousel
        swipeable={false}
        draggable={false}
        centerMode={true}
        responsive={responsive}
        ssr={true}
        infinite={true}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        >
        <Link to="/catalogue/birthday">
          <div className="carousel-box">
            <img src={BirthdayIcon} alt="Cumpleaños" />
            <span>Cumpleaños</span>
            <img className="arrow" src={Arrow} alt="Arrow" />
          </div>
        </Link>
        <Link to="">
          <div className="carousel-box disabled">
            <img src={WeddingIcon} alt="Boda" />
            <span>Boda</span>
            <img className="arrow" src={Arrow} alt="Arrow" />
          </div>
        </Link>
        <Link to="">
          <div className="carousel-box disabled">
            <img src={XVIcon} alt="XV años" />
            <span>XV años</span>
            <img className="arrow" src={Arrow} alt="Arrow" />
          </div>
        </Link>
        <Link to="">
          <div className="carousel-box disabled">
            <img src={BabyIcon} alt="Baby" />
            <span>Baby</span>
            <img className="arrow" src={Arrow} alt="Arrow" />
          </div>
        </Link>
        <Link to="">
          <div className="carousel-box disabled">
            <img src={ReligiousIcon} alt="Religiosas" />
            <span>Religiosas</span>
            <img className="arrow" src={Arrow} alt="Arrow" />
          </div>
        </Link>
        <Link to="">
          <div className="carousel-box disabled">
            <img src={GraduationIcon} alt="Graduacion" />
            <span>Graduación</span>
            <img className="arrow" src={Arrow} alt="Arrow" />
          </div>
        </Link>
      </Carousel>;
    </div>
  )
};

export default CarouselCat;
