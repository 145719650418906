import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styles from './Faq.module.scss';

const Faq = () => (
  <section className={styles['faq-wrapper']} data-aos="fade-in" id="faq">
    <div className="container">
      <h3>Preguntas Frecuentes</h3>

      <Accordion data-aos="zoom-in">
        <Accordion.Item eventKey="0">
          <Accordion.Header>¿Qué es un dominio personalizado?</Accordion.Header>
          <Accordion.Body>
            Un dominio es una dirección única en internet, adaptada según tus preferencias. Nosotros lo creamos como <b>tunombre.teinvitaasu.party</b>, para que sea fácil de recordar tanto para ti como para tus invitados.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>¿Cómo puedo personalizar mi invitación?</Accordion.Header>
          <Accordion.Body>
            Las invitaciones se customizan según tus necesidades.
            Después de realizar la compra, uno de nuestros asesores te pedirá las fotografías e información del evento y te brindará el soporte necesario para garantizar una experiencia excelente.
            Si aún no tienes una paleta de colores definida o necesitas más inspiración, puedes explorar nuestro catálogo.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>¿Qué formas de pago aceptan?</Accordion.Header>
          <Accordion.Body>
            Si te encuentras en la República Mexicana, puedes realizar tu pago de forma segura a través de Mercado Pago*, Clip*, depósito o transferencia bancaria. Desde otros países, podemos coordinar el pago mediante PayPal*. <br />
            <small>* Algunas plataformas pueden generar comisión.</small>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>¿En cuánto tiempo tendré mi invitación?</Accordion.Header>
          <Accordion.Body>
            Una vez recibida toda la información necesaria, recibirás la invitación en un plazo de 7 a 10 días hábiles. La misma estará disponible desde el momento en que la recibas hasta la fecha final del período indicado en tu paquete elegido.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>¿Puedo cambiar la información de mi invitación?</Accordion.Header>
          <Accordion.Body>
          Entendemos que los eventos pueden sufrir cambios, como la fecha o el lugar. Estos ajustes pueden realizarse con un costo adicional. Te recomendamos informar a tus invitados sobre cualquier modificación para que puedan tomar las precauciones necesarias.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>¿Cómo visualizo y comparto mi invitación?</Accordion.Header>
          <Accordion.Body>
            Tu invitación inteligente se enviará por WhatsApp o correo electrónico con un enlace personalizado que podrás compartir fácilmente por cualquier medio digital.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Quiero adquirir mi invitación, pero aún no tengo toda la información necesaria</Accordion.Header>
          <Accordion.Body>
            Congela el precio de tu invitación y envíanos la información cuando la tengas lista. Necesitamos al menos 2 semanas para la entrega. También ofrecemos la opción de enviar una pre-invitación para que tus invitados reserven la fecha, incluso si los detalles completos se envían después.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>¿Cuánto tiempo estará disponible mi invitación en línea?</Accordion.Header>
          <Accordion.Body>
            Algunos paquetes te permiten visualizar tu invitación hasta el día del evento. Si deseas extender la disponibilidad después de esta fecha, indícalo al realizar tu compra para que lo tomemos en cuenta en tu cotización.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>¿Puedo contratar una invitación si no estoy en México?</Accordion.Header>
          <Accordion.Body>
            ¡Sí! No importa dónde te encuentres, podemos crear tu invitación inteligente. Solo contáctanos y realiza tu pago.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>¿Eres organizador de eventos o tienes un salón de fiestas?</Accordion.Header>
          <Accordion.Body>
            Descubre los beneficios exclusivos para ti y tus clientes. ¡Contáctanos!
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  </section>
);

export default Faq;
