import React from 'react';
import styles from './Contact.module.scss';

const Contact = () => (
  <section className={styles['contact-wrapper']} data-aos="zoom-in-up" id="contact">
    <div className="container">
      <h3>Vamos a conocernos</h3>

      <div className={styles['box-contact']} data-aos="flip-up">
        <p>¿Tienes preguntas, comentarios o necesitas una cotización? <br />Escríbenos por correo electrónico o a través de nuestras redes sociales</p>

        <div className={styles['data']} data-aos="flip-down">
          <div className="d-flex flex-row align-items-center">
            <i className="bi bi-envelope-at-fill"></i>
            <p className="mb-0"><a href="mailto:teinvitasu.party@gmail.com" target="_blank" rel="noopener noreferrer">teinvitasu.party@gmail.com</a></p>
          </div>
          <div className="d-flex flex-row align-items-center">
            <i className="bi bi-instagram"></i>
            <p className="mb-0"><a href="https://www.instagram.com/teinvitaasu.party" target="_blank" rel="noopener noreferrer">@teinvitasu.party</a></p>
          </div>
        </div>

        <p className="mb-0 mt-3"><b>¡Será un placer atenderte!</b></p>
      </div>
    </div>
  </section>
);

export default Contact;
