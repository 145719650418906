import React from 'react';
import { Link } from "react-router-dom";
import LogoSvg from '../../svg/Logo';
import styles from './Header.module.scss';

const Header = () => {
  return <>
    <header className={styles['header-wrapper']}>
      <div className="container-fluid">
        <Link to="/">
          <LogoSvg />
        </Link>
      </div>
    </header>
  </>;
};

export default Header;
