import React from 'react';
import styles from './Steps-Buy.module.scss';

const StepsBuy = () => (
  <section className={styles['steps-buy-wrapper']} data-aos="fade-up" id="steps">
    <div className="container">
      <h3>Pasos para comprar</h3>

      <div className={styles['steps-container']}>
        <div className={styles['box']} data-aos="zoom-in-up">
          <div className={styles['number']}>1</div>
          <p>Selecciona el paquete que mejor se adapte a tu presupuesto y ponte en contacto con nosotros.</p>
        </div>

        <div className={styles['box']} data-aos="zoom-in-up">
          <div className={styles['number']}>2</div>
          <p>Uno de nuestros asesores te ofrecerá información sobre nuestras invitaciones y solicitará los detalles de tu evento para empezar con la creación.</p>
        </div>

        <div className={styles['box']} data-aos="zoom-in-up">
          <div className={styles['number']}>3</div>
          <p>Recibirás tu invitación en un plazo de 7 a 10 días hábiles y podrás compartir el enlace con todos tus invitados.</p>
        </div>
      </div>
    </div>
  </section>
);

export default StepsBuy;
