import React from 'react';
import Carousel from '../../utils/Carousel/Carousel';
import styles from './Catalogue.module.scss';

const Catalogue = () => {
  return (
    <section className={styles['catalogue-wrapper']} data-aos="zoom-in" id="catalogue">
      <div className="container">
        <h3 className="mb-0">Catálogo</h3>
        <p className={styles['text']}>¡Descubre nuestro catálogo! Cada modelo se personaliza según el estilo de tu evento. <br/>Explora nuestras opciones y conviértete en el anfitrión del futuro.</p>

        <Carousel />
      </div>
    </section>
  )
};

export default Catalogue;
