import React from 'react';
import styles from './Packages.module.scss';

const Packages = () => (
  <section className={styles['package-wrapper']} data-aos="fade-up" id="packages">
    <div className="container">
      <h3>Escoge tu paquete</h3>

      <div className={styles['package-container']}>
        <div className={styles['box']} data-aos="zoom-in">
          <figcaption className={styles['caption']}>
            <h6>ESTÁNDAR</h6>
          </figcaption>
          <ul>
            <li><i className="bi bi-pc-display"></i> Diseño optimizado para distintos dispositivos</li>
            <li><i className="bi bi-link-45deg"></i> Dominio personalizado</li>
            <li><i className="bi bi-calendar-heart-fill"></i> Detalles de tu evento; motivo, día, fecha y hora</li>
            <li><i className="bi bi-alarm-fill"></i> Cuenta regresiva</li>
            <li><i className="bi bi-person-check-fill"></i> Confirmación de asistencia por WhatsApp </li>
            <li><i className="bi bi-calendar-plus-fill"></i> Opción para incluir tu evento en el calendario</li>
            <li><i className="bi bi-geo-alt-fill"></i> Botón GPS con la ubicación</li>
            <li><i className="bi bi-card-list"></i>Itinerario del evento</li>
            <li><i className="bi bi-images"></i> Galería de fotos <b>(máximo 5)</b></li>
            <li><i className="bi bi-gift-fill"></i> Acceso a tus mesas de regalos, opciones para depósitos bancarios y/o temática de sobre</li>
            <li><i className="bi bi-person-standing-dress"></i> Código de vestimenta</li>
            <li><i className="bi bi-building-fill"></i> Opciones de hospedaje (Opcional)</li>
            <li><i className="bi bi-moisture"></i> Medidas de higiene y seguridad (Opcional)</li>
            <li><i className="bi bi-instagram"></i> Hashtag, Etiqueta y Comparte</li>
            <li><i className="bi bi-share-fill"></i> Comparte de manera ilimitada</li>
            <li><i className="bi bi-chat-left-text-fill"></i> <del>Sección de nombramientos (testigos, padrinos, familia, damas de honor, etc.)</del></li>
            <li><i className="bi bi-basket-fill"></i> <del>Menú</del></li>
            <li><i className="bi bi-music-note-beamed"></i><del>Música de fondo o grabación</del></li>
            <li><i className="bi bi-file-richtext-fill"></i><del>Save the Date: pre-invitación con la fecha de tu evento. (Opcional)</del></li>
            <li><i className="bi bi-ticket-perforated-fill"></i> <del>Boletos con QR para gestionar el acceso y pases asignados</del></li>
            <li><i className="bi bi-gear-fill"></i><del>Panel con enlaces únicos para cada invitado. <em>(Disponible solo al elegir tus boletos)</em></del></li>
            <li><i className="bi bi-calendar3"></i> Válido hasta <b>3 meses antes</b> del gran día</li>
          </ul>
          <p className={styles['price']}>$1250 <span>mxn</span></p>
        </div>

        <div className={styles['box']} data-aos="zoom-in">
          <figcaption className={styles['caption']}>
            <h6>PLUS</h6>
          </figcaption>
          <ul>
            <li><i className="bi bi-pc-display"></i> Diseño optimizado para distintos dispositivos</li>
            <li><i className="bi bi-link-45deg"></i> Dominio personalizado</li>
            <li><i className="bi bi-calendar-heart-fill"></i> Detalles de tu evento; motivo, día, fecha y hora</li>
            <li><i className="bi bi-alarm-fill"></i> Cuenta regresiva</li>
            <li><i className="bi bi-person-check-fill"></i> Confirmación de asistencia por WhatsApp </li>
            <li><i className="bi bi-calendar-plus-fill"></i> Opción para incluir tu evento en el calendario</li>
            <li><i className="bi bi-geo-alt-fill"></i> Botón GPS con la ubicación</li>
            <li><i className="bi bi-card-list"></i>Itinerario del evento</li>
            <li><i className="bi bi-images"></i> Galería de fotos <b>(máximo 8) o video</b></li>
            <li><i className="bi bi-gift-fill"></i> Acceso a tus mesas de regalos, opciones para depósitos bancarios y/o temática de sobre</li>
            <li><i className="bi bi-person-standing-dress"></i> Código de vestimenta</li>
            <li><i className="bi bi-building-fill"></i> Opciones de hospedaje (Opcional)</li>
            <li><i className="bi bi-moisture"></i> Medidas de higiene y seguridad (Opcional)</li>
            <li><i className="bi bi-instagram"></i> Hashtag, Etiqueta y Comparte</li>
            <li><i className="bi bi-share-fill"></i> Comparte de manera ilimitada</li>
            <li><i className="bi bi-chat-left-text-fill"></i> Sección de nombramientos (testigos, padrinos, familia, damas de honor, etc.)</li>
            <li><i className="bi bi-basket-fill"></i> <del>Menú</del></li>
            <li><i className="bi bi-music-note-beamed"></i> <del>Música de fondo o grabación</del></li>
            <li><i className="bi bi-file-richtext-fill"></i><del>Save the Date: pre-invitación con la fecha de tu evento. (Opcional)</del></li>
            <li><i className="bi bi-ticket-perforated-fill"></i> Boletos con QR para gestionar el acceso y pases asignados</li>
            <li><i className="bi bi-gear-fill"></i>Panel con enlaces únicos para cada invitado. <em>(Disponible solo al elegir tus boletos)</em></li>
            <li><i className="bi bi-calendar3"></i> Válido hasta <b>5 meses antes</b> del gran día</li>
          </ul>
          <p className={styles['price']}>$1900 <span>mxn</span></p>
        </div>

        <div className={styles['box']} data-aos="zoom-in">
          <figcaption className={styles['caption']}>
            <h6>PREMIUM</h6>
          </figcaption>
          <ul>
            <li><i className="bi bi-pc-display"></i> Diseño optimizado para distintos dispositivos</li>
            <li><i className="bi bi-link-45deg"></i> Dominio personalizado</li>
            <li><i className="bi bi-calendar-heart-fill"></i> Detalles de tu evento; motivo, día, fecha y hora</li>
            <li><i className="bi bi-alarm-fill"></i> Cuenta regresiva</li>
            <li><i className="bi bi-person-check-fill"></i> Confirmación de asistencia por WhatsApp </li>
            <li><i className="bi bi-calendar-plus-fill"></i> Opción para incluir tu evento en el calendario</li>
            <li><i className="bi bi-geo-alt-fill"></i> Botón GPS con la ubicación</li>
            <li><i className="bi bi-card-list"></i>Itinerario del evento</li>
            <li><i className="bi bi-images"></i> Galería de fotos <b>(máximo 10) y/o video</b></li>
            <li><i className="bi bi-gift-fill"></i> Acceso a tus mesas de regalos, opciones para depósitos bancarios y/o temática de sobre</li>
            <li><i className="bi bi-person-standing-dress"></i> Código de vestimenta</li>
            <li><i className="bi bi-building-fill"></i> Opciones de hospedaje (Opcional)</li>
            <li><i className="bi bi-moisture"></i> Medidas de higiene y seguridad (Opcional)</li>
            <li><i className="bi bi-instagram"></i> Hashtag, Etiqueta y Comparte</li>
            <li><i className="bi bi-share-fill"></i> Comparte de manera ilimitada</li>
            <li><i className="bi bi-chat-left-text-fill"></i> Sección de nombramientos (testigos, padrinos, familia, damas de honor, etc.)</li>
            <li><i className="bi bi-basket-fill"></i> Menú</li>
            <li><i className="bi bi-music-note-beamed"></i>Música de fondo o grabación</li>
            <li><i className="bu bi-file-richtext-fill"></i>Save the Date: pre-invitación con la fecha de tu evento. (Opcional)</li>
            <li><i className="bi bi-ticket-perforated-fill"></i> Boletos con QR para gestionar el acceso, pases asignados <b> y nombres de invitados</b></li>
            <li><i className="bi bi-gear-fill"></i>Panel con enlaces únicos para cada invitado, <b>con función de copia</b>. <em>(Disponible solo al elegir tus boletos)</em></li>
            <li><i className="bi bi-calendar3"></i> Válido hasta <b>1 mes después</b> del gran día</li>
          </ul>
          <p className={styles['price']}>$2450 <span>mxn</span></p>
        </div>
      </div>

      <p className={styles['add-text'] }><span className="text-stroke">¿Quieres personalizar tu paquete?</span> <br />Elimina secciones que no te interesen, incorpora opciones de otros paquetes o cuéntanos tu idea y la haremos realidad. <br /><span className="text-stroke">¡Cotiza ahora!</span></p>
    </div>
  </section>
);

export default Packages;
