import React from 'react';
import { useParams } from 'react-router-dom';
import Carousel from '../utils/Carousel/Carousel';
import Birthday from '../components/Categories/Birthday/Birthday';

const Catalogue = () => {
  const { cathegory } = useParams();

  return (
    <div className="container pt-5">
      <h3 className="pt-5 mb-0">Catálogo</h3>

      {(cathegory === 'birthday') && <Birthday />}

      <h5>Otras categorías</h5>
      <Carousel />
    </div>
  );
}

export default Catalogue;
