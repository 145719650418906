import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import styles from './Hero-Banner.module.scss';

const HeroBanner = () => (
  <section className={styles['hero-banner']} data-aos="flip-up">
    <div className="container">
      <div className="d-flex align-items-center justify-content-center">
        <div>
          <TypeAnimation
            className={styles['typing']}
            style={{ whiteSpace: 'pre-line', display: 'block' }}
            sequence={[
              500,
              `Invitaciones\nInteligentes para tu
              Cumpleaños`,
              1000,
              `Invitaciones\nInteligentes para tu
              Boda`,
              1000,
              `Invitaciones\nInteligentes para tus
              XV años`,
              1000,
              `Invitaciones\nInteligentes para tu
              Baby Shower`,
              1000,
              `Invitaciones\nInteligentes para tu
              Gender Reveal`,
              1000,
              `Invitaciones\nInteligentes para tu
              Bautizo`,
              1000,
              `Invitaciones\nInteligentes para tu
              Confirmación`,
              1000,
              `Invitaciones\nInteligentes para tu
              Primera comunión`,
              1000,
              `Invitaciones\nInteligentes para tu
              Aniversario`,
              1000,
              `Invitaciones\nInteligentes para tu
              Graduación`,
              1000,
              `Invitaciones\nInteligentes para tu
              Inauguración`,
              1000,
              `Invitaciones\nInteligentes para tu
              Open House`,
              1000,
              `Invitaciones\nInteligentes para tus
              Eventos sociales`,
              1000,
              `Invitaciones\nInteligentes para tus
              Eventos corporativos`,
              500,
            ]}
            speed={50}
            repeat={Infinity}
          />
        </div>
      </div>
    </div>
  </section>
);

export default HeroBanner;
