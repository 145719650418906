import React from 'react';
import Customizable from '../../assets/icons/customizable.svg';
import EcoFriendly from '../../assets/icons/eco-friendly.svg';
import Economic from '../../assets/icons/economic.svg';
import Global from '../../assets/icons/global.svg';
import Interactive from '../../assets/icons/interactive.svg';
import Responsive from '../../assets/icons/responsive.svg';
import Share from '../../assets/icons/unlimited-shipping.svg';

import styles from './Content.module.scss';

const Content = () => (
  <section className={styles['content-wrapper']} data-aos="fade-right" id="content">
    <div className="container">
      <p>
        <b>Disfruta de una experiencia de planificación de eventos más cómoda y original. </b>
        Comparte todos los detalles de tu celebración de manera rápida, moderna y ecológica con nuestra propuesta innovadora.
      </p>

      <div className={styles['features']}>
        <div className={styles['box']}>
          <img src={EcoFriendly} alt="Eco-Friendly" data-aos="flip-right" />
          <p className={styles.title}>Eco-friendly</p>
        </div>

        <div className={styles['box']}>
          <img src={Economic} alt="Económicas" data-aos="flip-right" />
          <p className={styles.title}>Económicas</p>
        </div>

        <div className={styles['box']}>
          <img src={Customizable} alt="Personalizables" data-aos="flip-right" />
          <p className={styles.title}>Personalizables</p>
        </div>

        <div className={styles['box']}>
          <img src={Global} alt="Global" data-aos="flip-right" />
          <p className={styles.title}>Acceso Global</p>
        </div>

        <div className={styles['box']}>
          <img src={Interactive} alt="Interactivas" data-aos="flip-right" />
          <p className={styles.title}>Interactivas</p>
        </div>

        <div className={styles['box']}>
          <img src={Responsive} alt="Diseño Adaptable" data-aos="flip-right" />
          <p className={styles.title}>Diseño Adaptable</p>
        </div>

        <div className={styles['box']}>
          <img src={Share} alt="Envíos Ilimitados" data-aos="flip-right" />
          <p className={styles.title}>Envíos ilimitados</p>
        </div>
      </div>
    </div>
  </section>
);

export default Content;
