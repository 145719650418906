import React, { useEffect, useState } from 'react';
import './App.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from "react-router-dom";
import party from "party-js";
import AppRoutes from './utils/App.router';
import Footer from './utils/Footer/Footer';
import Header from './utils/Header/Header';
import Loader from './utils/Loader/Loader'
import Sidebar from './utils/Sidebar/Sidebar';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  useEffect(() => {
    AOS.init();

    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace("#", ""));

      if (!elementToScroll) return;

      window.scrollTo({
        top: elementToScroll.offsetTop - 0,
        behavior: "smooth"
      });
    };

    if (!isLoading) {
      scrollToHashElement();
    }

    scrollToHashElement();
    window.addEventListener("hashchange", scrollToHashElement);
    return window.removeEventListener("hashchange", scrollToHashElement);
  }, [isLoading, location])

  const setExplodingConfetti = () => {
    party.confetti(document.body, {
      count: party.variation.range(500, 1000),
    });
  };

  return (
    <div className="main" onClick={setExplodingConfetti} onContextMenu={(e)=> e.preventDefault()}>
      {isLoading ?
        <Loader />
      :
      <>
        <Sidebar />
        <main id="page-wrap">
          <Header />
          <AppRoutes/>
          <Footer />
        </main>
      </>
      }
    </div>
  );
}

export default App;
