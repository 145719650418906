import React from 'react';
import Arrow from '../../../assets/icons/arrow.svg';
import BirthdayIcon from '../../../assets/icons/birthday.svg';
import MonstersIncPage from '../../../assets/img/cover/monsters-inc.svg';
import styles from '../Categories.module.scss';

const Birthday = () => {
  return (
    <section className={styles['categories-wrapper']} data-aos="zoom-in">
      <div className="container">
        <h5>
          <img src={BirthdayIcon} alt="Cumpleaños" />
          Cumpleaños
        </h5>

        <div className={styles['invitations-container']} >
          <a className={styles['item-box']} href="https://monsters-inc.teinvitaasu.party/" target='no_blank'>
            <img src={MonstersIncPage} alt="Invitación Monsters Inc" />
            <img className={styles['arrow']} src={Arrow} alt="Arrow" />
          </a>
        </div>
      </div>
    </section>
  )
};

export default Birthday;