import React, {useState} from 'react';
import { Link } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClose = () => setShowMenu(false);
  const handleShow = () => setShowMenu(true);

  return (
    <>
      <i className={`${styles['burger-btn']} bi bi-list`} onClick={handleShow}></i>

      <Offcanvas show={showMenu} onHide={handleClose} placement="end" backdrop={false} scroll={false}>
        <Offcanvas.Header>
          <i className={`bi bi-x ${styles['close-btn']}`} onClick={handleClose}></i>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className={`nav ${styles['menu']}`} id="menu">
            <li className="nav-item" onClick={handleClose}>
              <Link to="/#page-wrap">
                Home
              </Link>
            </li>
            <li className="nav-item" onClick={handleClose}>
              <Link to="/#content">
                Contenido
              </Link>
            </li>
            <li className="nav-item" onClick={handleClose}>
              <Link to="/#catalogue">
                Catálogo
              </Link>
            </li>
            <li className="nav-item" onClick={handleClose}>
              <Link to="/#packages">
                Paquetes
              </Link>
            </li>
            <li className="nav-item" onClick={handleClose}>
              <Link to="/#steps">
                Pasos para comprar
              </Link>
            </li>
            <li className="nav-item" onClick={handleClose}>
              <Link to="/#contact">
                Contacto
              </Link>
            </li>
            <li className="nav-item" onClick={handleClose}>
              <Link to="/#faq">
                Faq
              </Link>
            </li>
          </ul>

          <div className={styles['contact']}>
            <div className="d-flex flex-row align-items-center">
              <i className="bi bi-envelope-at-fill"></i>
              <p className="mb-0"><a href="mailto:teinvitasu.party@gmail.com" target="_blank" rel="noopener noreferrer">teinvitasu.party@gmail.com</a></p>
            </div>
            <div className="d-flex flex-row align-items-center">
              <i className="bi bi-instagram"></i>
              <p className="mb-0"><a href="https://www.instagram.com/teinvitaasu.party" target="_blank" rel="noopener noreferrer">@teinvitasu.party</a></p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default Sidebar;
