import React from 'react';
import Catalogue from '../components/Catalogue/Catalogue';
import Contact from '../components/Contact/Contact';
import Content from '../components/Content/Content';
import Faq from '../components/Faq/Faq';
import HeroBanner from '../components/Hero-Banner/Hero-Banner';
import Options from '../components/Options/Options';
import Packages from '../components/Packages/Packages';
import StepsBuy from '../components/Steps-Buy/Steps-Buy';

const Home = () => {
  return (
    <>
      <HeroBanner />
      <Content/>
      <Options />
      <Catalogue />
      <Packages />
      <StepsBuy />
      <Contact />
      <Faq />
    </>
  );
}

export default Home;
